import { loadScript } from 'helpers/scriptUtils';

const OMICX_URL = `/js/omicxcall-web-sdk.min.js`;

// const SIP_DOMAIN = 'voice.f88.vn'; //"800255", "123456a@"
// const SIP_DOMAIN = 'cloud.telebot.vn'; //"526", "450967577197"
// const SIP_DOMAIN = 'call.metechvn.com'; //"1008", "nD6*c3t*ahqR"

const OmicxDialerComponent = ({
  webPhone,
  domain,
  username,
  password,
  isCalling,
  setStateLabel = () => {},
  setTotalSeconds = () => {},
  updateWebPhone = () => {},
  setConnected = () => {},
  onCallId = () => {},
  setIsCalling = () => {},
  setIsMuted = () => {},
  setIsHeld = () => {},
  callRequest,
  endCallRequest,
  muteRequest,
  holdRequest,
  unmuteRequest,
  unholdRequest,
  setCallRequest = () => {},
  setEndCallRequest = () => {},
  setMuteRequest = () => {},
  setUnmuteRequest = () => {},
  setHoldRequest = () => {},
  setUnholdRequest = () => {},
}) => {
  let totalSeconds;

  useEffect(() => {
    if (hasCallUser() && !webPhone) {
      runScript();
    }
  }, []);

  const runScript = () => {
    loadScript(OMICX_URL)
      .then(() => {
        updateWebPhone(window.omicx.outbound);
      })
      .catch((error) => {
        console.log('Error when loading script: ', error);
      });
  };

  useEffect(() => {
    if (webPhone && webPhone.connected == null) {
      webPhone.loginAsync(username, password, domain).then(() => {
        console.log('Web phone connected');
        setConnected(true);
      });
    }
  }, [webPhone]);

  useEffect(() => {
    if (callRequest && callRequest.target && webPhone) {
      call(callRequest.target);
    }
    setCallRequest(false);
  }, [callRequest]);

  useEffect(() => {
    endCallRequest && endCall();
    setEndCallRequest(false);
  }, [endCallRequest]);

  useEffect(() => {
    muteRequest && mute();
    setMuteRequest(false);
  }, [muteRequest]);

  useEffect(() => {
    unmuteRequest && unmute();
    setUnmuteRequest(false);
  }, [unmuteRequest]);

  useEffect(() => {
    holdRequest && hold();
    setHoldRequest(false);
  }, [holdRequest]);

  useEffect(() => {
    unholdRequest && unhold();
    setUnholdRequest(false);
  }, [unholdRequest]);

  function call(toNumber, callback = () => {}) {
    setIsCalling(true);
    setStateLabel('Đang gọi');

    let intervalId;
    webPhone.stateManager.on('state', (state) => {
      if (callback && callback != null) callback({ toNumber, state });

      switch (state) {
        case 'Establishing':
          console.log('omicx establishing');
          setStateLabel('Đang kết nối');
          totalSeconds = 0;
          setTotalSeconds(totalSeconds);
          break;
        case 'Established':
          console.log('omicx established');
          setStateLabel('Đã kết nối');
          totalSeconds = 0;
          setTotalSeconds(totalSeconds);
          intervalId = setInterval(addTotalSeconds, 1000);
          break;
        case 'UnMuted':
          setIsMuted(false);
          break;
        case 'UnHold':
          setIsHeld(false);
          break;
        case 'Muted':
          setIsMuted(true);
          break;
        case 'Hold':
          setIsHeld(true);
          break;
        case 'Terminating':
        case 'Terminated':
          console.log('omicx terminating');
          setStateLabel('Kết thúc');
          clearInterval(intervalId);
          setIsCalling(false);
          break;
        // Forbidden, Cancelled, Unavailable
        default:
          setStateLabel(state);
      }
    });

    const callId = webPhone.makecall(toNumber);
    onCallId(callId);
  }

  const endCall = () => {
    webPhone.endcall();
  };

  const mute = () => {
    webPhone.mute();
  };

  const unmute = () => {
    webPhone.unmute();
  };

  const hold = () => {
    webPhone.hold();
  };

  const unhold = () => {
    webPhone.unhold();
  };

  const addTotalSeconds = () => {
    totalSeconds++;
    setTotalSeconds(totalSeconds);
  };

  const hasCallUser = () => {
    return username && password;
  };

  return '';
};

export default OmicxDialerComponent;
