import { WEBPHONE_PROVIDER, WEBPHONE_SDK } from 'components/dialer/constants';
import { WebPhoneConfig } from 'components/dialer/types';
import { AccountDetail } from 'redux/account/types';

export const isV1BE = () => {
  const partnerCode = import.meta.env.VITE_PARTNER_CODE;
  return partnerCode === 'TNEX' || partnerCode === 'PTF';
};

export const isTnex = () => {
  const partnerCode = import.meta.env.VITE_PARTNER_CODE;
  return partnerCode === 'TNEX';
};

export const isPtf = () => {
  const partnerCode = import.meta.env.VITE_PARTNER_CODE;
  return partnerCode === 'PTF';
};

export const getDialerConfigs = (user: AccountDetail): WebPhoneConfig[] => {
  const partnerCode = import.meta.env.VITE_PARTNER_CODE;
  switch (partnerCode) {
    case 'TNEX':
      return [
        {
          type: WEBPHONE_SDK.PBX,
          provider: WEBPHONE_PROVIDER.CGV,
          username: user?.callUsername,
          password: user?.callPassword,
          domain: 'cloud.telebot.vn',
        },
      ];
    case 'PTF':
      return [
        {
          type: WEBPHONE_SDK.STRINGEE,
          provider: WEBPHONE_PROVIDER.STRINGEE,
          username: '',
          password: '',
          domain: '',
        },
      ];
    default:
      return [];
  }
};
