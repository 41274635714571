import { useSelector } from 'react-redux';
import './dialog-content.scss';
import { formatLocalDate } from 'helpers/dateFormat';
import { vnd } from 'helpers/numberFormat';
import { useCollectionActions } from '../collection-info-container';
import { CollectionInfoTable } from './collection-info-table';

const LABEL_SUMMARY_MAP = {
  total: 'Tổng',
  due: 'Tổng đến hạn',
  overdue: 'Tổng quá hạn',
  tod: 'Phải thu hôm nay',
};

const PAYMENT_STATUS = {
  NOTDUE: 'Chưa đến hạn',
  UNPAIDNE: 'Chưa thanh toán đủ',
  OVERDUE: 'Quá hạn',
  PAID: 'Đã thanh toán hết',
  UNPAID: 'Chưa thanh toán',
};

const DEFAULT_PAGINATION = {
  pageNumber: 0,
  totalItems: 10,
};

// const dummySummaryData = {
//   total: {
//     totalPrinciple: 3720964,
//     totalInterest: 242327,
//     totalOverdueInterest: 6761950,
//     total: 10725241,
//   },
//   overdue: {
//     totalPrinciple: 3720964,
//     totalInterest: 242327,
//     totalOverdueInterest: 6761950,
//     total: 10725241,
//   },
//   due: {
//     totalPrinciple: 0,
//     totalInterest: 0,
//     totalOverdueInterest: 0,
//     total: 0,
//   },
//   tod: {
//     totalPrinciple: 3720964,
//     totalInterest: 242327,
//     totalOverdueInterest: 6761950,
//     total: 10725241,
//   },
// };

const columns = [
  {
    key: 'repayDate',
    label: 'Kỳ thanh toán',
    render: (row) => <div>{formatLocalDate(row.entry.repayDate)}</div>,
  },
  {
    key: 'status',
    label: 'Trạng thái',
    align: 'center',
    render: (row) => <div>{PAYMENT_STATUS[row.entry.status]}</div>,
  },
  {
    key: 'dpd',
    label: 'DPD',
    align: 'center',
    render: (row) => <div>{row.entry.dpd}</div>,
  },
  {
    key: 'primciple',
    label: 'Gốc',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.principle)}</div>,
  },
  {
    key: 'interest',
    label: 'Lãi',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.interest)}</div>,
  },
  {
    key: 'interestOverdue',
    label: 'Lãi quá hạn',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.interestOverdue)}</div>,
  },
  {
    key: 'total',
    label: 'Tổng phải thu',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.total)}</div>,
  },
];

// const summaryColumns = [
//   {
//     key: 'label',
//     colSpan: 3,
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{row.label}</div>,
//   },
//   {
//     key: 'totalPrinciple',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.totalPrinciple)}</div>,
//   },
//   {
//     key: 'totalInterest',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.totalInterest)}</div>,
//   },
//   {
//     key: 'totalOverdueInterest',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.totalOverdueInterest)}</div>,
//   },
//   {
//     key: 'total',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.total)}</div>,
//   },
// ];

// const handleSummaryData = (summaryData) => {
//   const result = [];
//   Object.keys(dummySummaryData).map((item) => result.push({ label: LABEL_SUMMARY_MAP[item], ...summaryData[item] }));
//   return result;
// };

export function CollectionPlan({ id }) {
  const collectionPlanData = useSelector((state) => state.Lead.collectionPlan);
  const action = useCollectionActions();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    action.fetchCollectionPlan({ id, page: 0, pageItem: 10 });
  }, []);

  const handleChangePage = (page) => {
    setPage(page);
    action.fetchCollectionPlan({ id, page, pageItem: rowsPerPage });
  };

  const handleChangeRowsPerPage = (number) => {
    setRowsPerPage(number);
    setPage(0);
    action.fetchCollectionPlan({ id, page: 0, pageItem: number });
  };

  return (
    <div className="tab-content">
      <CollectionInfoTable
        columns={columns}
        rows={collectionPlanData?.list?.entries ?? []}
        pagination={collectionPlanData?.list?.pagination ?? DEFAULT_PAGINATION}
        onChangePage={(page) => handleChangePage(page)}
        onChangeRowsPerPage={(number) => handleChangeRowsPerPage(number)}
        rowsPerPage={rowsPerPage}
        page={page}
        // summaryColumns={summaryColumns}
        // summaryRows={handleSummaryData(dummySummaryData)}
      />
    </div>
  );
}
