export const WEBPHONE_SDK = {
  OMICX: 'OMICX',
  PBX: 'PBX',
  CGV: 'CGV',
  STRINGEE: 'STRINGEE',
};

export const WEBPHONE_PROVIDER = {
  F88: 'F88',
  CGV: 'CGV',
  STRINGEE: 'STRINGEE',
};
