import DialerContext from './dialer-context';
import ExternalDialer from './external/external-dialer';
import InternalDialer from './internal/internal-dialer';

function NexustiDialerProvider({ webPhoneList, onCalling = () => {}, onAvailable = () => {}, children }) {
  const [call, setCall] = useState(() => {});

  /* Last call info */
  const [callId, setCallId] = useState();
  const [callProvider, setCallProvider] = useState();
  const [toNumber, setToNumber] = useState();

  const [isOnCall, setIsOnCall] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const getChildContext = () => {
    return {
      callId,
      callProvider,
      call,
      setDisabled,
      isOnCall,
      toNumber,
    };
  };

  return (
    <DialerContext.Provider value={getChildContext()}>
      {children}
      {import.meta.env.VITE_EXTERNAL_DIALER ? (
        <ExternalDialer
          webPhoneList={webPhoneList}
          onCalling={onCalling}
          onAvailable={onAvailable}
          setCall={setCall}
          setCallId={setCallId}
          setCallProvider={setCallProvider}
          setToNumber={setToNumber}
          setIsOnCall={setIsOnCall}
          disabled={disabled}
        />
      ) : (
        <InternalDialer
          webPhoneList={webPhoneList}
          onCalling={onCalling}
          onAvailable={onAvailable}
          setCall={setCall}
          setCallId={setCallId}
          setCallProvider={setCallProvider}
          setToNumber={setToNumber}
          setIsOnCall={setIsOnCall}
          disabled={disabled}
        />
      )}
    </DialerContext.Provider>
  );
}

export default NexustiDialerProvider;
