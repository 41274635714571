import { useSelector } from 'react-redux';
import { useCollectionActions } from '../collection-info-container';
import './dialog-content.scss';
import { CollectionInfoTable } from './collection-info-table';
import { formatLocalDate } from 'helpers/dateFormat';
import { vnd } from 'helpers/numberFormat';

const DEFAULT_PAGINATION = {
  pageNumber: 0,
  totalItems: 10,
};

// const summaryDumyData = {
//   total: 8084908,
//   totalPrinciple: 6779036,
//   totalFee: 0,
//   totalPenalty: 0,
// };

const columns = [
  {
    key: 'transactionNumber',
    label: 'Mã giao dịch',
    render: (row) => <div>{row.entry.transactionNumber}</div>,
  },
  {
    key: 'paymentDate',
    label: 'Ngày giao dịch',
    align: 'center',
    render: (row) => <div>{formatLocalDate(row.entry.paymentDate)}</div>,
  },
  {
    key: 'paymentAmount',
    label: 'Số tiền thanh toán',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.paymentAmount)}</div>,
  },
  {
    key: 'principle',
    label: 'Gốc',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.principle)}</div>,
  },
  {
    key: 'interest',
    label: 'Lãi',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.interest)}</div>,
  },
  {
    key: 'interestOverdue',
    label: 'Lãi quá hạn',
    align: 'right',
    render: (row) => <div>{vnd(row.entry.interestOverdue)}</div>,
  },
];

// const summaryColumns = [
//   {
//     key: 'label',
//     colSpan: 2,
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{row.label}</div>,
//   },
//   {
//     key: 'total',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.total)}</div>,
//   },
//   {
//     key: 'totalPrinciple',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.totalPrinciple)}</div>,
//   },
//   {
//     key: 'totalFee',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.totalFee)}</div>,
//   },
//   {
//     key: 'totalPenalty',
//     align: 'right',
//     render: (row) => <div style={{ fontWeight: '600' }}>{vnd(row.totalPenalty)}</div>,
//   },
// ];

// const handleSummaryData = (summaryData) => {
//   return [{ label: 'Tổng', ...summaryData }];
// };

export function CollectionPayment({ id }) {
  const collectionPayementData = useSelector((state) => state.Lead.collectionPayment);
  const action = useCollectionActions();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    action.fetchCollectionPayment({ id, page: 0, pageItem: 10 });
  }, []);

  const handleChangePage = (page) => {
    setPage(page);
    action.fetchCollectionPayment({ id, page, pageItem: rowsPerPage });
  };

  const handleChangeRowsPerPage = (number) => {
    setRowsPerPage(number);
    setPage(0);
    action.fetchCollectionPayment({ id, page: 0, pageItem: number });
  };

  return (
    <div className="tab-content">
      <CollectionInfoTable
        columns={columns}
        rows={collectionPayementData?.list?.entries ?? []}
        pagination={collectionPayementData?.list?.pagination ?? DEFAULT_PAGINATION}
        onChangePage={(page) => handleChangePage(page)}
        onChangeRowsPerPage={(number) => handleChangeRowsPerPage(number)}
        rowsPerPage={rowsPerPage}
        page={page}
        // summaryColumns={summaryColumns}
        // summaryRows={handleSummaryData(summaryDumyData)}
      />
    </div>
  );
}
