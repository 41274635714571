import { AutosaveModeContext } from 'AutosaveMode';

interface AutosaveProps {
  children: React.ReactNode;
}

export default function Autosave({ children }: AutosaveProps) {
  const [mode, setMode] = useState<boolean>(false);
  const autosaveMode = useMemo(
    () => ({
      toggleAutosaveMode: () => {
        setMode((prevMode) => !prevMode);
      },
      mode,
    }),
    [mode]
  );

  return <AutosaveModeContext.Provider value={autosaveMode}>{children}</AutosaveModeContext.Provider>;
}
