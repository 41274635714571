import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { isV1BE } from 'helpers/envUtils';
import { prepareAuthHeaders } from 'helpers/api';
import { DataArraySharp } from '@mui/icons-material';
import { DecisionQueryValue, DecisionResultValue, Product } from './types';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.PRODUCT_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['productGroups', 'product', 'products', 'productHybrids'],
  endpoints: (builder) => ({
    fetchProductGroup: builder.query({
      query: () => `product/group/get-all`,
      providesTags: ['productGroups'],
    }),
    filterProducts: builder.query({
      query: ({ filter, pagination }) => ({
        url: `product/filter`,
        method: 'POST',
        params: pagination,
        body: filter,
      }),
    }),
    fetchAllProducts: builder.query<Product[], void>({
      query: () => 'product/get-all',
      providesTags: ['products'],
    }),
    fetchAllProductHybrids: builder.query<Product[], any>({
      query: (params) => ({
        url: `product/get-all/hybrid`,
        method: 'GET',
        params,
      }),
      providesTags: ['productHybrids'],
    }),
    fetchProductDetail: builder.query<Product, string>({
      query: (productCode) => `product/code/${productCode}`,
      providesTags: ['product'],
    }),
    fetchProductValue: builder.query<DecisionResultValue, DecisionQueryValue>({
      query: ({ code, data }) => ({
        url: `/product/decision/product-value/${code}`,
        method: 'POST',
        body: data,
      }),
    }),
    fetchFeeValue: builder.query<DecisionResultValue, DecisionQueryValue>({
      query: ({ code, data }) => ({
        url: `/product/decision/fee-value/${code}`,
        method: 'POST',
        body: data,
      }),
    }),
    fetchCommissionValue: builder.query<DecisionResultValue, DecisionQueryValue>({
      query: ({ code, data }) => ({
        url: `/product/decision/commission-value/${code}`,
        method: 'POST',
        body: data,
      }),
    }),

    createProduct: builder.mutation({
      async queryFn({ product, uploadDocuments }, _queryApi, _extraOptions, fetch) {
        let result = await fetch({
          url: `product`,
          method: 'POST',
          body: product,
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };

        if (product.logoUrl) {
          result = await fetch({
            url: `product/${product.code}/logo`,
            method: 'POST',
            body: product,
          });
        }

        // TODO: Get productCode of created product, then upload documents if exist
        return result.data ? { data: result.data } : { error: result.error as FetchBaseQueryError };
      },
      invalidatesTags: ['products'],
    }),
    updateProduct: builder.mutation({
      async queryFn({ product }, _queryApi, _extraOptions, fetch) {
        let result = await fetch({
          url: `product/${product.code}`,
          method: 'PUT',
          body: product,
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };

        if (product.logoUrl) {
          result = await fetch({
            url: `product/${product.code}/logo`,
            method: 'POST',
            body: product,
          });
        }

        return result.data ? { data: result.data } : { error: result.error as FetchBaseQueryError };
      },
      invalidatesTags: ['product'],
    }),

    fetchInsurances: builder.query({
      query: ({ pagination }) => ({
        url: `insurance/get-all/pagination`,
        method: 'GET',
        params: pagination,
      }),
    }),
    fetchAllInsurances: builder.query({
      query: () => ({
        url: `insurance/get-all`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchProductGroupQuery,

  useFetchAllProductsQuery,
  useFetchAllProductHybridsQuery,
  useFilterProductsQuery,
  useFetchProductDetailQuery,

  useFetchProductValueQuery,
  useFetchFeeValueQuery,
  useLazyFetchFeeValueQuery,
  useFetchCommissionValueQuery,

  useLazyFilterProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,

  useFetchInsurancesQuery,
  useLazyFetchInsurancesQuery,
  useLazyFetchAllInsurancesQuery,
  useFetchAllInsurancesQuery,
} = productApi;
