import { Suspense } from 'react';
import { useDispatch } from 'react-redux';
// import HealthCheck from 'healthCheck';
import { updateUserStatus } from 'redux/actions';
import { USER_STATES } from 'constants/userState';
import DialerProvider from 'components/dialer/dialer-provider';
import { getLoggedInUser } from 'helpers/authUtils';
import NxRouterContainer from 'router';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import AppTheme from 'layouts/theme/AppTheme';
import { I18nextProvider } from 'react-i18next';
import ReloadPrompt from 'ReloadPrompt';
import { getDialerConfigs } from 'helpers/envUtils';
import Autosave from 'layouts/theme/Autosave';
import i18n from './i18n';

const Notification = lazy(() => import('Notification'));

/**
 * Main app component
 */

function App() {
  // const isProduction = import.meta.env.NODE_ENV === 'production';

  const user = getLoggedInUser();

  const dispatch = useDispatch();

  const handleChangeStatus = (status: string) => {
    dispatch(updateUserStatus(status));
  };

  const onCalling = () => {
    handleChangeStatus(USER_STATES.inCall);
  };

  const onAvailable = () => {
    handleChangeStatus(USER_STATES.available);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <AppTheme>
          <Toaster />
          <CssBaseline />
          <Suspense fallback={null}>
            <Notification />
          </Suspense>
          <Autosave>
            <DialerProvider webPhoneList={getDialerConfigs(user)} onCalling={onCalling} onAvailable={onAvailable}>
              <BrowserRouter>
                <NxRouterContainer />
              </BrowserRouter>
            </DialerProvider>
          </Autosave>
          {/* <HealthCheck /> */}
          <ReloadPrompt />
        </AppTheme>
      </StyledEngineProvider>
    </I18nextProvider>
  );
}

export default App;
